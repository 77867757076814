import React from 'react';
import 'fontsource-roboto/300.css';
import 'fontsource-roboto/400.css';
import 'fontsource-roboto/700.css';

import { theme, ThemeProvider } from 'styles/styled';
import GlobalStyle from 'styles/global';

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
);

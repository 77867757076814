import { createGlobalStyle } from 'styles/styled';
import ArrowIcon from 'images/icons/circle-arrow.svg';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    background-color: ${({ theme }) => theme.palette.dark};
    font-family: 'Roboto', sans-serif;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input {
    -webkit-border-radius: 0;
    border-radius: 0;
    filter: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  /* remove background color for autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }

  :-moz-autofill, :-moz-autofill-preview {
    filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);
  }

  b, strong {
    font-weight: 700;
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: ${({ theme }) => theme.ui.transition('opacity')};

    &:hover {
      opacity: ${({ theme }) => theme.ui.opacity()};
    }
  }

  h1, h2, h3, p, em {
    color: ${({ theme }) => theme.palette.light};

    strong {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSize.title};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    letter-spacing: 1.1px;

    @media ${({ theme }) => theme.devices.large} {
      font-size: ${({ theme }) => theme.fontSize.titleDesktopBig};
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.titleMobile};
    letter-spacing: 1px;

    @media ${({ theme }) => theme.devices.large} {
      font-size: ${({ theme }) => theme.fontSize.title};
    }
  }

  h3, p, em, a {
    font-size: ${({ theme }) => theme.fontSize.smallBase};
    line-height: 1.2;
    letter-spacing: 0.75px;
  }

  p, em {
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }

  li {
    color: ${({ theme }) => theme.palette.light};
    font-size: ${({ theme }) => theme.fontSize.smallBase};
  }

  span {
    color: ${({ theme }) => theme.palette.light};
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    width: 100%;
  }

  button {
    outline: 0;
    background: transparent;
    cursor: pointer;
  }

   /* Swiper.js */
   .swiper-container {
     width: 100%;
     height: 100%;
     overflow: visible;
  }

  .swiper-slide {
    height: 100%;
    width: 100%;
  }

  .swiper-pagination {
    bottom: -30px !important;
  }

  .swiper-pagination-bullet {
    background: transparent;
    opacity: 1;
    border: 1.5px solid ${({ theme }) => theme.palette.light};
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.palette.light};
  }

  .swiper-button-prev, .swiper-button-next {
    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    transition: ${({ theme }) => theme.ui.transition('opacity')};

    &:hover {
      opacity: ${({ theme }) => theme.ui.opacity()};
    }

    &:after {
      display: none;
    }

    @media ${({ theme }) => theme.devices.large} {
      display: none;
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
  }

  .cam-swiper {
    .swiper-button-prev, .swiper-button-next {
      top: 34%;
    }
  }
`;

export default GlobalStyle;

import * as styledComponents from 'styled-components';

type DevicesSize = {
  xsmall: number;
  small: number;
  medium: number;
  normal: number;
  large: number;
  xlarge: number;
  xxlarge: number;
};

const deviceSize = {
  xsmall: 320,
  small: 576,
  normal: 640,
  medium: 768,
  large: 1024,
  xlarge: 1200,
  xxlarge: 1440,
  fullHd: 1920,
};

type Devices = {
  xsmall: string;
  small: string;
  medium: string;
  large: string;
  xlarge: string;
  xxlarge: string;
  layoutChange: string;
  layoutChangeBig: string;
  horizontalMobile: string;
  normalDesktopHeight: string;
  fullHd: string;
  shortDesktop: string;
};

const devices = {
  xsmall: `(min-width: ${deviceSize.xsmall}px)`,
  small: `(min-width: ${deviceSize.small}px)`,
  medium: `(min-width: ${deviceSize.medium}px)`,
  large: `(min-width: ${deviceSize.large}px)`,
  xlarge: `(min-width: ${deviceSize.xlarge}px)`,
  xxlarge: `(min-width: ${deviceSize.xxlarge}px)`,
  fullHd: `(min-width: ${deviceSize.fullHd}px)`,
  layoutChange: `(min-height: ${deviceSize.normal}px) and (min-width: ${deviceSize.large}px)`,
  layoutChangeBig: `(min-height: ${deviceSize.medium}px) and (min-width: ${deviceSize.xxlarge}px)`,
  horizontalMobile: `(orientation: landscape) and (max-height: ${deviceSize.small}px)`,
  normalDesktopHeight: `(min-height: ${deviceSize.medium}px) and (min-width: ${deviceSize.large}px)`,
  shortDesktop: `(min-width: 1024px) and (max-height: 639px)`,
};

type Dimensions = {
  contentMaxWidthBig: number;
  contentMaxWidthMedium: number;
  contentMaxWidthSmall: number;
  sliderTextContentMaxWidth: number;
  blogContentMaxWidth: number;
};

const dimensions = {
  contentMaxWidthBig: 1200,
  contentMaxWidthMedium: 960,
  contentMaxWidthSmall: 632,
  sliderTextContentMaxWidth: 675,
  blogContentMaxWidth: 512,
};

type FontSize = {
  label: string;
  smallBase: string;
  base: string;
  subtitleMobile: string;
  titleMobile: string;
  title: string;
  titleDesktop: string;
  titleDesktopBig: string;
  titleDesktopHuge: string;
};

const fontSize = {
  label: '14px',
  smallBase: '15px',
  base: '16px',
  subtitleMobile: '18px',
  titleMobile: '24px',
  title: '26px',
  titleDesktop: '32px',
  titleDesktopBig: '48px',
  titleDesktopHuge: '132px',
};

type FontWeight = {
  light: number;
  regular: number;
  bold: number;
};

const fontWeight = {
  light: 300,
  regular: 400,
  bold: 700,
};

type Palette = {
  blogAccent: string;
  primary: string;
  primaryDark: string;
  secondary: string;
  danger: string;
  dark: string;
  lightDark: string;
  mediumDark: string;
  light: string;
  lightOpacity: string;
  lightDarkOpacity: string;
};

const palette = {
  blogAccent: '#28b4e1',
  primary: '#00adee',
  primaryDark: '#37a8d1',
  secondary: '#37a8d1',
  danger: '#ff4242',
  dark: '#000000',
  lightDark: 'rgba(0,0,0,0.45)',
  mediumDark: '#021636',
  light: '#ffffff',
  lightOpacity: 'rgba(255, 255, 255, 0.96)',
  lightDarkOpacity: 'rgba(255, 255, 255, 0.69)',
};

type UI = {
  border(color?: string): string;
  borderRadius: string;
  opacity(value?: string): string;
  transition(property: string, multiplier?: number): string;
};

const ui = {
  border: (color = palette.light) => `2px solid ${color}`,
  borderRadius: '25px',
  transition: (prop: string, multiplier = 1) =>
    `${prop} ${multiplier * 150}ms ease-in`,
  opacity: (value: string) => `${value || 0.8}`,
};

export interface ThemeInterface {
  deviceSize: DevicesSize;
  devices: Devices;
  dimensions: Dimensions;
  fontSize: FontSize;
  fontWeight: FontWeight;
  palette: Palette;
  ui: UI;
}

export const theme: ThemeInterface = {
  deviceSize,
  devices,
  dimensions,
  fontSize,
  fontWeight,
  palette,
  ui,
};

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
} = styledComponents as unknown as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export { css, createGlobalStyle, keyframes, ThemeProvider, useTheme };
export default styled;
